import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CustomersRepository } from '../../api/CustomersRepository'
import { TaxRepository } from '../../api/TaxRepository'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { SentryUtils } from '../../utils/SentryUtils'
import Email from '../components/Email'
import { setEmails } from '../../redux/state-slices/EmailSlice'
import {
    setError,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import Loading from '../../shared-components/Loading'
import ErrorV2 from '../../shared-components/ErrorV2'
import {
    validateAddress,
    validateCity,
    validateCountryV2,
    validateInputKey,
    validateName,
    validateNotes,
    validatePostalCode,
    validateProvince,
    validateTax,
} from '../../utils/RegexHelper'

export default function CustomersEdit() {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state)
    const navigate = useNavigate()

    const customerRepository = new CustomersRepository()
    const taxRepository = new TaxRepository()

    const taxQuery = useQuery('taxes', taxRepository.getTaxes)
    const customerQuery = useQuery(['customer', id], async () => {
        return await customerRepository.getCustomer(
            SentryUtils.expect<number>(id ? parseInt(id) : 0)
        )
    })
    const customer = customerQuery.data?.data
    const taxes = taxQuery?.data?.data

    const countries = ['USA', 'CAD', 'MEX']

    const [validations, setValidations] = useState({
        first_name: { value: '', validated: true },
        last_name: { value: '', validated: true },
        address: { value: '', validated: true },
        city: { value: '', validated: true },
        province: { value: '', validated: true },
        postal: { value: '', validated: true },
        country: { value: '', validated: true },
        tax: { value: 0, validated: true },
        notes: { value: '', validated: true },
    })

    const updateCustomerMutation = useMutation({
        mutationFn: () =>
            customerRepository.updateCustomer(
                id ? parseInt(id) : 0,
                validations.first_name.value,
                validations.last_name.value,
                state.customer.emails,
                validations.address.value,
                validations.city.value,
                validations.province.value,
                validations.postal.value,
                validations.country.value,
                validations.tax.value,
                validations.notes.value
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },

        onSuccess: (data) => {
            dispatch(
                setSuccess([
                    `${validations.first_name.value} ${validations.last_name.value} has been updated`,
                ])
            )
            navigate(`/invoicing/customers/${id}`)
        },
    })

    const validate = (data: Paywell.API.V2.ValidationData[]) => {
        data.forEach((v) => {
            setValidations((prevValidations) => ({
                ...prevValidations,
                [v.name]: {
                    value: v.value,
                    validated:
                        v.value != null && v.value.length > 2
                            ? v.f(v.value)
                            : true,
                },
            }))
        })
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        updateCustomerMutation.mutate()
    }

    useEffect(() => {
        if (customer) {
            validate([
                {
                    value: customer.first_name,
                    f: validateName,
                    name: 'first_name',
                },
                {
                    value: customer.last_name,
                    f: validateName,
                    name: 'last_name',
                },
                {
                    value: customer.billing_address,
                    f: validateAddress,
                    name: 'address',
                },
                {
                    value: customer.billing_city,
                    f: validateCity,
                    name: 'city',
                },
                {
                    value: customer.billing_province,
                    f: validateProvince,
                    name: 'province',
                },
                {
                    value: customer.billing_postal_code,
                    f: validatePostalCode,
                    name: 'postal',
                },
                {
                    value: customer.billing_country,
                    f: validateCountryV2,
                    name: 'country',
                },
                {
                    value: customer.tax_id,
                    f: validateTax,
                    name: 'tax',
                },
                {
                    value: customer.notes,
                    f: validateNotes,
                    name: 'notes',
                },
            ])
            dispatch(
                setEmails(
                    JSON.parse(customer?.emails).map((email: any) => ({
                        ...email,
                        validated: true,
                    }))
                )
            )
        }
    }, [customer])

    if (customerQuery.isLoading)
        return (
            <div>
                <Loading width="8" height="8" />
            </div>
        )

    if (customerQuery.isError)
        return (
            <div>
                <ErrorV2
                    title="An error has occured"
                    message="An error has occurred while loading your customer. Please try refreshing, if this keeps happening contact support."
                />
            </div>
        )

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="sm:flex ">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Edit Customer
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Edit Customer #{id}
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                to={'/invoicing/customers'}
                            >
                                Cancel
                            </Link>
                            <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                disabled={updateCustomerMutation.isLoading}
                            >
                                {updateCustomerMutation.isLoading ? (
                                    <Loading height="6" width="6" />
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Personal Information{' '}
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Use a permanent address where you can
                                    receive mail.
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Full Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="off"
                                            defaultValue={
                                                validations.first_name.value
                                            }
                                            className={`block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm
                                                ${
                                                    validations.first_name
                                                        .validated
                                                        ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                        : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                }`}
                                            pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                            title="Please enter a valid first name"
                                            onKeyUp={(e) =>
                                                validateInputKey(
                                                    e.key,
                                                    (
                                                        e.target as HTMLInputElement
                                                    ).value
                                                ) &&
                                                validate([
                                                    {
                                                        value: (
                                                            e.target as HTMLInputElement
                                                        ).value,
                                                        f: validateName,
                                                        name: 'first_name',
                                                    },
                                                ])
                                            }
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="off"
                                            defaultValue={
                                                validations.last_name.value
                                            }
                                            className={`block w-full max-w-lg rounded-md sm:max-w-xs sm:text-sm
                                                ${
                                                    validations.last_name
                                                        .validated
                                                        ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                        : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                }`}
                                            pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                            title="Please enter a valid last name"
                                            onKeyUp={(e) =>
                                                validateInputKey(
                                                    e.key,
                                                    (
                                                        e.target as HTMLInputElement
                                                    ).value
                                                ) &&
                                                validate([
                                                    {
                                                        value: (
                                                            e.target as HTMLInputElement
                                                        ).value,
                                                        f: validateName,
                                                        name: 'last_name',
                                                    },
                                                ])
                                            }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Email address
                                    </label>

                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <Email />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="address"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Address
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col gap-4">
                                        <div className="flex gap-4">
                                            <input
                                                type="text"
                                                name="street-address"
                                                id="street-address"
                                                autoComplete="off"
                                                placeholder="Street address"
                                                defaultValue={
                                                    validations.address.value
                                                }
                                                className={`block w-full max-w-lg rounded-md shadow-sm sm:text-sm
                                                    ${
                                                        validations.address
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                title="Please enter a valid street address"
                                                onKeyUp={(e) =>
                                                    validateInputKey(
                                                        e.key,
                                                        (
                                                            e.target as HTMLInputElement
                                                        ).value
                                                    ) &&
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: validateAddress,
                                                            name: 'address',
                                                        },
                                                    ])
                                                }
                                                required
                                            />
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                autoComplete="off"
                                                placeholder="City"
                                                defaultValue={
                                                    validations.city.value
                                                }
                                                className={`block w-full max-w-lg rounded-md sm:text-sm
                                                    ${
                                                        validations.city
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                                title="Please enter a valid city"
                                                onKeyUp={(e) =>
                                                    validateInputKey(
                                                        e.key,
                                                        (
                                                            e.target as HTMLInputElement
                                                        ).value
                                                    ) &&
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: validateCity,
                                                            name: 'city',
                                                        },
                                                    ])
                                                }
                                                required
                                            />
                                        </div>
                                        <div className="flex gap-4">
                                            <input
                                                type="text"
                                                name="state-province"
                                                id="state-province"
                                                autoComplete="off"
                                                placeholder="State/Province"
                                                defaultValue={
                                                    validations.province.value
                                                }
                                                className={`block w-full max-w-lg rounded-md shadow-sm sm:text-sm
                                                    ${
                                                        validations.province
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                                title="Please enter a valid province / state"
                                                onKeyUp={(e) =>
                                                    validateInputKey(
                                                        e.key,
                                                        (
                                                            e.target as HTMLInputElement
                                                        ).value
                                                    ) &&
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: validateProvince,
                                                            name: 'province',
                                                        },
                                                    ])
                                                }
                                                required
                                            />
                                            <input
                                                type="text"
                                                name="postal-code"
                                                id="postal-code"
                                                autoComplete="off"
                                                placeholder="Postal Code"
                                                defaultValue={
                                                    validations.postal.value
                                                }
                                                className={`block w-full max-w-lg rounded-md
                                                    ${
                                                        validations.postal
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                pattern="^[a-zA-Z0-9]+$"
                                                title="Please enter a valid postal code"
                                                onKeyUp={(e) =>
                                                    validateInputKey(
                                                        e.key,
                                                        (
                                                            e.target as HTMLInputElement
                                                        ).value
                                                    ) &&
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: validatePostalCode,
                                                            name: 'postal',
                                                        },
                                                    ])
                                                }
                                                required
                                            />
                                            <select
                                                id="country"
                                                name="country"
                                                defaultValue={
                                                    validations.country.value
                                                }
                                                className={`mt-1 block w-full rounded-md py-2 pl-3 pr-10 text-base sm:text-sm
                                                    ${
                                                        validations.country
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                onChange={(e) =>
                                                    validate([
                                                        {
                                                            value: e.target
                                                                .value,
                                                            f: validateCountryV2,
                                                            name: 'country',
                                                        },
                                                    ])
                                                }
                                                required
                                            >
                                                {countries.map((c) => (
                                                    <option
                                                        value={c}
                                                        selected={
                                                            c ==
                                                            validations.country
                                                                .value
                                                        }
                                                    >
                                                        {c}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-y-8 divide-y divide-gray-200 ">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ">
                    <div className="space-y-6 sm:space-y-5 ">
                        <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 ">
                            <div className="sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Other Information{' '}
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Fill out their tax rates and any notes{' '}
                                </p>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Tax Rate
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <select
                                            id="tax-rates"
                                            name="tax-rates"
                                            className={`mt-1 block rounded-md py-2 pl-3 pr-10 text-base sm:text-sm
                                                ${
                                                    validations.tax.validated
                                                        ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                        : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                }`}
                                            onChange={(e) => {
                                                validate([
                                                    {
                                                        value: e.target.value,
                                                        f: validateTax,
                                                        name: 'tax',
                                                    },
                                                ])
                                            }}
                                            required
                                        >
                                            {taxes &&
                                                taxes.map((t, index) => (
                                                    <option
                                                        value={t.id}
                                                        selected={
                                                            t.id ==
                                                            validations.tax
                                                                .value
                                                        }
                                                    >
                                                        {t.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Notes
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                        <textarea
                                            rows={4}
                                            name="comment"
                                            id="comment"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            defaultValue={
                                                validations.notes.value
                                            }
                                            onKeyUp={(e) =>
                                                validate([
                                                    {
                                                        value: (
                                                            e.target as HTMLInputElement
                                                        ).value,
                                                        f: validateNotes,
                                                        name: 'notes',
                                                    },
                                                ])
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
