import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React, { useState, Fragment } from 'react'
import { getReadableSupplier, SUPPLIER } from '../utils/UserUtils'
import { useMutation } from 'react-query'
import { CommissionsRepository } from '../api/CommissionsRepository'
import Loading from '../shared-components/Loading'
import { useAppDispatch } from '../redux/hooks'
import {
    setError,
    setSuccess,
} from '../redux/state-slices/GlobalNotificationSlice'

export default function DataImport() {
    const commisionsRepository = new CommissionsRepository()
    const dispatch = useAppDispatch()
    const [errors, setErrors] = useState<string[]>([])
    const [selected, setSelected] = useState<number>(0)
    const [formData, setFormData] = useState<FormData | null>(null)
    const [fileName, setFileName] = useState<string | null>(null)

    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ')
    }

    const uploadDataMutation = useMutation(
        (args: { formData: FormData }) =>
            commisionsRepository.uploadData(args.formData, selected),

        {
            onSuccess: (data) => {
                dispatch(setSuccess([`Successfully uploaded data`]))
            },
            onError: (error: any) => {
                if (error.data.message) {
                    const message = error.data.message
                    setErrors([...message])
                } else {
                    setErrors(['An error occurred while generating invoices'])
                }
            },
        }
    )
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name
            setFileName(fileName)
            let fieldName = 'supplier_data'
            formData.append(fieldName, e.target.files[0], fileName)
            setFormData(formData)
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setErrors([])
        if (formData && selected !== 0) {
            uploadDataMutation.mutate({ formData })
        }
    }

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
            <div className="mx-auto max-w-3xl">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-12 sm:space-y-16">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Upload Transactions
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                Upload your transactions from a various sources.
                            </p>

                            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                        Select Data Type
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <Listbox
                                            value={selected}
                                            onChange={(num) => setSelected(num)}
                                        >
                                            {({ open }) => (
                                                <>
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <span className="block truncate">
                                                                {selected
                                                                    ? getReadableSupplier(
                                                                          selected
                                                                      )
                                                                    : 'Select a data type'}
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ChevronUpDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {Object.entries(
                                                                    SUPPLIER
                                                                ).map(
                                                                    ([
                                                                        id,
                                                                        name,
                                                                    ]) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                id
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                classNames(
                                                                                    active
                                                                                        ? 'bg-indigo-600 text-white'
                                                                                        : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={
                                                                                id
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                                active,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            selected
                                                                                                ? 'font-semibold'
                                                                                                : 'font-normal',
                                                                                            'block truncate'
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </span>

                                                                                    {selected && (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active
                                                                                                    ? 'text-white'
                                                                                                    : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="h-5 w-5"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                                    >
                                        Upload
                                    </label>
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>
                                                    {fileName
                                                        ? fileName
                                                        : 'Upload a file'}
                                                </span>
                                                <input
                                                    onChange={handleFileUpload}
                                                    id="file-upload"
                                                    name="file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    accept=".csv"
                                                />
                                            </label>
                                            {fileName ? (
                                                ''
                                            ) : (
                                                <p className="pl-1">
                                                    or drag and drop
                                                </p>
                                            )}
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            CSV
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            Back
                        </button>
                        {uploadDataMutation.isLoading && (
                            <Loading height={'8'} width={'8'} />
                        )}
                        {!uploadDataMutation.isLoading && (
                            <button
                                disabled={
                                    uploadDataMutation.isLoading ||
                                    !formData ||
                                    !selected
                                }
                                type="submit"
                                className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-indigo-600 disabled:text-white disabled:shadow-sm disabled:hover:bg-indigo-600 disabled:focus-visible:outline disabled:focus-visible:outline-2 disabled:focus-visible:outline-offset disabled:focus-visible:outline-indigo-600"
                            >
                                Upload
                            </button>
                        )}
                    </div>
                    {errors.length > 0 && (
                        <div className="text-red-500 text-center italic mt-4">
                            {errors.map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
}
