import {
    getReadableAccountStatus,
    getReadableBusinessFormation,
    getReadableCountry,
    getReadableProvince,
    getReadableSupplier,
    getReadableUserType,
} from '../utils/UserUtils'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface Props {
    userCopy: Paywell.API.V2.User | null
    isAdmin: boolean
}

export default function UserInfo({ userCopy, isAdmin }: Props) {
    const navigate = useNavigate()

    return (
        <div className={`p-10`}>
            {isAdmin && (
                <div className="mb-2 flex justify-end">
                    <button
                        onClick={() =>
                            navigate('/users/' + userCopy?.id + '/info/edit')
                        }
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Edit Page
                    </button>
                </div>
            )}
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Merchant Business Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        View and edit merchant information.
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    User MID
                                </dt>

                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.mid}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Account Status
                                </dt>

                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableAccountStatus(
                                        userCopy?.account_status_id || 0
                                    )}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Username/Legal{' '}
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.name}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Email
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                    {userCopy?.email}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Business Formation{' '}
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableBusinessFormation(
                                        userCopy?.business_formation
                                    )}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Services
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.merchandise_sold}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Business Number{' '}
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.business_number}
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Tax Number{' '}
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.tax_number}
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    DBA name{' '}
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.nickname}
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    User Type{' '}
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableUserType(
                                        userCopy?.user_type_id || 0
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Phone
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.business_tel_number}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Website
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.website}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Jurisdiction
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.jurisdiction}
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Accountant Email
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">
                                    {userCopy?.accountant_email}
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Agent
                                </dt>
                                <dd className="mt-1 text-sm leading-6  sm:col-span-2 sm:mt-0 text-indigo-500">
                                    <a
                                        target="_blank"
                                        href={
                                            '/users/' +
                                            userCopy?.referrer_user_id +
                                            '/info'
                                        }
                                    >
                                        View
                                    </a>
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Supplier
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableSupplier(
                                        userCopy?.supplier_id || 0
                                    )}
                                </dd>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Master
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.mmerchant}
                                </dd>
                            </div>
                        </dl>{' '}
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Business Contact Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        View and edit business contact information.
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2  gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Address
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.address}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    City
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.city}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Postal Code
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.postal_code}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Province/State
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableProvince(
                                        userCopy?.provinces_id || 0
                                    )}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Country
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {getReadableCountry(
                                        userCopy?.countries_id || 0
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Documents
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2  gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Business Registration
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.business_registration_file_name ? (
                                        <a
                                            href={
                                                userCopy.business_registration_image_url ||
                                                ''
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500"
                                        >
                                            View
                                        </a>
                                    ) : (
                                        'Not available'
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Proof of Address
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.proof_of_address_file_name ? (
                                        <a
                                            href={
                                                userCopy.proof_of_address_image_url ||
                                                ''
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-blue-500"
                                        >
                                            View
                                        </a>
                                    ) : (
                                        'Not available'
                                    )}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}
