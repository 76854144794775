import {
    ACCOUNT_STATUS_OPTIONS,
    COUNTRIES,
    getReadableAccountStatus,
    getReadableBusinessFormation,
    getReadableCountry,
    getReadableProvince,
    getReadableSupplier,
    PROVINCES,
    SUPPLIER,
    USER_TYPES,
} from '../../utils/UserUtils'
import { Transition } from '@headlessui/react'

import React, { Fragment, useEffect, useState } from 'react'
import {
    alphaNumericWithSpacesRegex,
    numOnlyRegex,
    testWebsiteRegex,
    validateBusinessName,
    validateEmailAddress,
    validateMerchandiseSold,
} from '../../utils/RegexHelper'
import { useNavigate } from 'react-router-dom'
import { Listbox } from '@headlessui/react'
import {
    ArrowUpTrayIcon,
    CheckIcon,
    ChevronUpDownIcon,
} from '@heroicons/react/20/solid'
import DialogModal from '../../shared-components/DialogModal'
import { UserRepository } from '../../api/UserRepositoy'
import { useMutation, useQuery } from 'react-query'
import { setError } from '../../redux/state-slices/GlobalNotificationSlice'
import { useAppDispatch } from '../../redux/hooks'
import Loading from '../../shared-components/Loading'
import { extractFileName } from '../../utils/StringUtils'

interface Props {
    user: Paywell.API.V2.User | null
    userCopy: Paywell.API.V2.User | null
    updateUserInfo: (user: Paywell.API.V2.User) => void
    saveChanges: () => void
}

const businessFormations = [
    'association_estate_trust',
    'corporation',
    'international_organization',
    'llc',
    'medical_legal_corporation',
    'non_profit',
    'partnership',
    'sole_proprietorship',
    'tax_exempt_organization',
]

export default function UserInfoEdit({
    user,
    userCopy,
    updateUserInfo,
    saveChanges,
}: Props) {
    const userRepository = new UserRepository()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [validName, setValidName] = useState(true)
    const [validEmail, setValidEmail] = useState(true)
    const [validMerchandiseSold, setValidMerchandiseSold] = useState(true)
    const [validBusinessNumber, setValidBusinessNumber] = useState(true)
    const [validTaxNumber, setValidTaxNumber] = useState(true)
    const [validPhoneNumber, setValidPhoneNumber] = useState(true)
    const [validWebsite, setValidWebsite] = useState(true)
    const [validAccountantEmail, setValidAccountantEmail] = useState(true)
    const [validMMerchant, setValidMMerchant] = useState(true)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    useState(false)
    const validForm =
        validName &&
        //validEmail &&
        validMerchandiseSold &&
        validBusinessNumber &&
        validTaxNumber &&
        validPhoneNumber &&
        validWebsite &&
        validAccountantEmail &&
        validMMerchant

    useEffect(() => {
        validateForm()
    }, [userCopy])

    const fetchUsersAndAdmins = async () => {
        return (await userRepository.getAgentsAndAdmins()).data
    }

    const userQuery = useQuery(
        ['agents-and-admins'],
        () => fetchUsersAndAdmins(),
        {
            onSuccess: (data) => {},
            onError: (error) => {
                dispatch(
                    setError(['Error retrieving agents. Please try again'])
                )
            },
            refetchOnWindowFocus: false,
            retry: false,
        }
    )

    const uploadProofOfAddressMutation = useMutation(
        (args: { formData: FormData; userId: number; fileName: string }) =>
            userRepository.uploadProofOfAddress(args.formData, args.userId),

        {
            onSuccess: (_, { fileName }) => {
                updateProofOfAddressFileName(fileName)
            },
            onError: () => {
                dispatch(setError(['There was an error uploading the file']))
            },
        }
    )

    const uploadBusinessRegistrationMutation = useMutation(
        (args: { formData: FormData; userId: number; fileName: string }) =>
            userRepository.uploadBusinessRegistration(
                args.formData,
                args.userId
            ),

        {
            onSuccess: (_, { fileName }) => {
                updateBusinessAddressFileName(fileName)
            },
            onError: () => {
                dispatch(setError(['There was an error uploading the file']))
            },
        }
    )
    const updateProofOfAddressFileName = (fileName: string) => {
        if (!userCopy) return
        updateUserInfo({
            ...userCopy,
            proof_of_address_file_name: fileName,
        })
    }

    const updateBusinessAddressFileName = (fileName: string) => {
        if (!userCopy) return
        updateUserInfo({
            ...userCopy,
            business_registration_file_name: fileName,
        })
    }

    const handleUploadFile = (
        e: React.ChangeEvent<HTMLInputElement>,
        userId: number,
        type: 'proofofaddress' | 'businessregistration'
    ) => {
        const formData = new FormData()
        if (e.target.files?.length === 1) {
            let fileName = e.target.files[0].name
            let fieldName = type

            formData.append(fieldName, e.target.files[0], fileName)

            if (type === 'proofofaddress') {
                uploadProofOfAddressMutation.mutate({
                    formData,
                    userId,
                    fileName,
                })
            } else if (type === 'businessregistration') {
                uploadBusinessRegistrationMutation.mutate({
                    formData,
                    userId,
                    fileName,
                })
            }
        }
        e.target.value = '' //reset the input field
    }
    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ')
    }

    const validateForm = () => {
        if (!userCopy) return

        setValidName(
            Boolean(
                userCopy.name &&
                    userCopy.name.length > 0 &&
                    userCopy.name.length < 50 &&
                    validateBusinessName(userCopy.name)
            )
        )

        /* setValidEmail(
            Boolean(
                userCopy.email &&
                    userCopy.email.length > 0 &&
                    userCopy.email.length < 50 &&
                    validateEmailAddress(userCopy.email)
            )
        )*/

        setValidMerchandiseSold(
            Boolean(
                userCopy.merchandise_sold === null ||
                    userCopy.merchandise_sold === '' ||
                    validateMerchandiseSold(userCopy.merchandise_sold)
            )
        )

        setValidBusinessNumber(
            Boolean(
                userCopy.business_number === null ||
                    userCopy.business_number === '' ||
                    alphaNumericWithSpacesRegex(userCopy.business_number)
            )
        )

        setValidTaxNumber(
            Boolean(
                userCopy.tax_number === null ||
                    userCopy.tax_number === '' ||
                    alphaNumericWithSpacesRegex(userCopy.tax_number)
            )
        )

        setValidPhoneNumber(
            Boolean(
                userCopy.business_tel_number === null ||
                    userCopy.business_tel_number === '' ||
                    numOnlyRegex(userCopy.business_tel_number)
            )
        )

        setValidWebsite(
            Boolean(
                userCopy.website === null ||
                    userCopy.website === '' ||
                    testWebsiteRegex(userCopy.website)
            )
        )

        setValidAccountantEmail(
            Boolean(
                userCopy.accountant_email === null ||
                    userCopy.accountant_email === '' ||
                    validateEmailAddress(userCopy.accountant_email)
            )
        )

        if (userCopy.mmerchant === null) {
            setValidMMerchant(true)
        } else {
            setValidMMerchant(
                Boolean(numOnlyRegex(userCopy.mmerchant.toString()))
            )
        }
    }
    const handleSaveChanges = () => {
        if (!validForm) return
        saveChanges()
    }
    const handleMidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length <= 45) {
            updateUserInfo({
                ...userCopy,
                mid: e.target.value,
            })
        }
    }

    const handleAccountStatusChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                account_status_id: val,
            })
        }
    }
    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                name: e.target.value,
            })
        }
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                email: e.target.value,
            })
        }
    }

    const handleBusinessFormationChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                business_formation: val,
            })
        }
    }

    const handleMerchandiseSold = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                merchandise_sold: e.target.value,
            })
        }
    }

    const handleBusinessNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length <= 9) {
            updateUserInfo({
                ...userCopy,
                business_number: e.target.value,
            })
        }
    }
    const handleTaxNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                tax_number: e.target.value,
            })
        }
    }

    const handleDbaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 100) {
            updateUserInfo({
                ...userCopy,
                nickname: e.target.value,
            })
        }
    }

    const userTypeOptions = Object.entries(USER_TYPES).map(([id, name]) => ({
        id,
        name,
    }))

    const handleUserTypeChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                user_type_id: val,
            })
        }
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 25) {
            updateUserInfo({
                ...userCopy,
                business_tel_number: val,
            })
        }
    }

    const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                website: val,
            })
        }
    }

    const handleJuriChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                jurisdiction: val,
            })
        }
    }

    const handleAccountantEmailChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                accountant_email: val,
            })
        }
    }

    const handleAgentChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                referrer_user_id: val,
            })
        }
    }

    const handleSupplierChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                supplier_id: val,
            })
        }
    }

    const handleMMerchantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (!val && userCopy) {
            updateUserInfo({
                ...userCopy,
                mmerchant: null,
            })
        } else if (userCopy && val.length < 9 && numOnlyRegex(val)) {
            updateUserInfo({
                ...userCopy,
                mmerchant: parseInt(val),
            })
        }
    }

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                address: val,
            })
        }
    }

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 50) {
            updateUserInfo({
                ...userCopy,
                city: val,
            })
        }
    }

    const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        if (userCopy && val.length < 10) {
            updateUserInfo({
                ...userCopy,
                postal_code: val,
            })
        }
    }

    const handleProvinceChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                provinces_id: val,
            })
        }
    }

    const handleCountryChange = (val: any) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                countries_id: val,
            })
        }
    }

    const hasUnsavedChanges = () => {
        return JSON.stringify(user) !== JSON.stringify(userCopy)
    }

    const handleCancel = () => {
        if (!user) return
        if (hasUnsavedChanges()) {
            setIsDialogOpen(true) // Show the dialog if there are unsaved changes
        } else {
            navigate('/users/' + user.id + '/info') // No unsaved changes, just navigate away
        }
    }

    const confirmCancel = () => {
        setIsDialogOpen(false) // Close the dialog
        if (user) updateUserInfo(user) // Reset the user to the original state (cancel changes
        navigate('/users/' + user?.id + '/info') // Proceed with the cancel action
    }

    const cancelCancel = () => {
        setIsDialogOpen(false) // Close the dialog without canceling changes
    }

    const invalidClass =
        '!border-2 !border-rose-500 focus:ring-rose-500 focus:border-rose-500'

    const agentsAndAdmins = userQuery.data

    return (
        <div className={`p-10 relative`}>
            {(uploadProofOfAddressMutation.isLoading ||
                uploadBusinessRegistrationMutation.isLoading) && (
                <div
                    id="loading-screen"
                    className=" w-full h-full absolute block top-0 left-0 bg-white opacity-75 z-50"
                >
                    <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                        <Loading height={'8'} width={'8'} />
                    </span>
                </div>
            )}
            <div className="mb-2 flex justify-end space-x-2">
                <button
                    disabled={!validForm}
                    onClick={handleSaveChanges}
                    type="button"
                    className={`block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm disabled:bg-gray-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    type="button"
                    className="flex  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Cancel
                </button>
            </div>

            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Merchant Business Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        View and edit merchant information.
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    User MID
                                </dt>

                                <input
                                    onChange={handleMidChange}
                                    type="text"
                                    name="user-mid"
                                    id="user-mid"
                                    value={userCopy?.mid || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Account Status
                                </dt>

                                <Listbox
                                    value={userCopy?.account_status_id}
                                    onChange={handleAccountStatusChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {getReadableAccountStatus(
                                                            userCopy?.account_status_id ||
                                                                0
                                                        )}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {Object.entries(
                                                            ACCOUNT_STATUS_OPTIONS
                                                        ).map(([id, name]) => (
                                                            <Listbox.Option
                                                                key={id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-indigo-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={id}
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate'
                                                                            )}
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                        </span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-indigo-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Username/Legal{' '}
                                </dt>
                                <input
                                    onChange={handleUserNameChange}
                                    type="text"
                                    name="user-name"
                                    id="user-name"
                                    value={userCopy?.name || ''}
                                    className={`${
                                        !validName && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Email
                                </dt>
                                <input
                                    onChange={handleEmailChange}
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={userCopy?.email || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6 disabled:bg-gray-100`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Business Formation{' '}
                                </dt>
                                <Listbox
                                    value={userCopy?.business_formation}
                                    onChange={handleBusinessFormationChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {userCopy?.business_formation
                                                            ? getReadableBusinessFormation(
                                                                  userCopy?.business_formation ||
                                                                      null
                                                              )
                                                            : 'Select Business Formation'}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {businessFormations.map(
                                                            (formation) => (
                                                                <Listbox.Option
                                                                    key={
                                                                        formation
                                                                    }
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        classNames(
                                                                            active
                                                                                ? 'bg-indigo-600 text-white'
                                                                                : 'text-gray-900',
                                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                        )
                                                                    }
                                                                    value={
                                                                        formation
                                                                    }
                                                                >
                                                                    {({
                                                                        selected,
                                                                        active,
                                                                    }) => (
                                                                        <>
                                                                            <span
                                                                                className={classNames(
                                                                                    selected
                                                                                        ? 'font-semibold'
                                                                                        : 'font-normal',
                                                                                    'block truncate'
                                                                                )}
                                                                            >
                                                                                {getReadableBusinessFormation(
                                                                                    formation
                                                                                )}
                                                                            </span>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active
                                                                                            ? 'text-white'
                                                                                            : 'text-indigo-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon
                                                                                        className="h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Services
                                </dt>
                                <input
                                    onChange={handleMerchandiseSold}
                                    type="text"
                                    name="services"
                                    id="services"
                                    value={userCopy?.merchandise_sold || ''}
                                    className={`${
                                        !validMerchandiseSold && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Business Number{' '}
                                </dt>
                                <input
                                    onChange={handleBusinessNumberChange}
                                    type="text"
                                    name="business-number"
                                    id="business-number"
                                    value={userCopy?.business_number || ''}
                                    className={`${
                                        !validBusinessNumber && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Tax Number{' '}
                                </dt>

                                <input
                                    onChange={handleTaxNumberChange}
                                    type="text"
                                    name="tax-number"
                                    id="tax-number"
                                    value={userCopy?.tax_number || ''}
                                    className={`${
                                        !validTaxNumber && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    DBA name{' '}
                                </dt>

                                <input
                                    onChange={handleDbaChange}
                                    type="text"
                                    name="dba-name"
                                    id="dba-name"
                                    value={userCopy?.nickname || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    User Type{' '}
                                </dt>
                                <Listbox
                                    value={userCopy?.user_type_id}
                                    onChange={handleUserTypeChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {userCopy?.user_type_id
                                                            ? USER_TYPES[
                                                                  userCopy
                                                                      .user_type_id
                                                              ]
                                                            : 'Select a user type'}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {userTypeOptions.map(
                                                            ({ id, name }) => (
                                                                <Listbox.Option
                                                                    key={id}
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                                                            active
                                                                                ? 'bg-indigo-600 text-white'
                                                                                : 'text-gray-900'
                                                                        }`
                                                                    }
                                                                    value={id}
                                                                >
                                                                    {({
                                                                        selected,
                                                                        active,
                                                                    }) => (
                                                                        <>
                                                                            <span
                                                                                className={`block truncate ${
                                                                                    selected
                                                                                        ? 'font-semibold'
                                                                                        : 'font-normal'
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    name
                                                                                }
                                                                            </span>
                                                                            {selected && (
                                                                                <span
                                                                                    className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                                                                        active
                                                                                            ? 'text-white'
                                                                                            : 'text-indigo-600'
                                                                                    }`}
                                                                                >
                                                                                    <CheckIcon
                                                                                        className="h-5 w-5"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Phone
                                </dt>
                                <input
                                    onChange={handlePhoneChange}
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    value={userCopy?.business_tel_number || ''}
                                    className={`${
                                        !validPhoneNumber && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Website
                                </dt>
                                <input
                                    onChange={handleWebsiteChange}
                                    type="text"
                                    name="website"
                                    id="website"
                                    value={userCopy?.website || ''}
                                    className={`${
                                        !validWebsite && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Jurisdiction
                                </dt>
                                <input
                                    onChange={handleJuriChange}
                                    type="text"
                                    name="jurisdiction"
                                    id="jurisdiction"
                                    value={userCopy?.jurisdiction || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Accountant Email
                                </dt>
                                <input
                                    onChange={handleAccountantEmailChange}
                                    type="text"
                                    name="accountant-email"
                                    id="accountant-email"
                                    value={userCopy?.accountant_email || ''}
                                    className={`${
                                        !validAccountantEmail && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Agent
                                </dt>
                                {userQuery.isSuccess &&
                                    !userQuery.isLoading &&
                                    !userQuery.isFetching &&
                                    agentsAndAdmins && (
                                        <Listbox
                                            value={
                                                agentsAndAdmins[
                                                    userCopy?.referrer_user_id ||
                                                        0
                                                ] ??
                                                Object.values(
                                                    agentsAndAdmins
                                                )[0]
                                            }
                                            onChange={handleAgentChange}
                                        >
                                            {({ open }) => (
                                                <>
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <span className="block truncate">
                                                                {
                                                                    agentsAndAdmins[
                                                                        userCopy?.referrer_user_id ||
                                                                            0
                                                                    ]
                                                                }
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ChevronUpDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {Object.entries(
                                                                    agentsAndAdmins
                                                                ).map(
                                                                    ([
                                                                        id,
                                                                        name,
                                                                    ]) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                id
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                classNames(
                                                                                    active
                                                                                        ? 'bg-indigo-600 text-white'
                                                                                        : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={
                                                                                id
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                                active,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            selected
                                                                                                ? 'font-semibold'
                                                                                                : 'font-normal',
                                                                                            'block truncate'
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </span>

                                                                                    {selected && (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active
                                                                                                    ? 'text-white'
                                                                                                    : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="h-5 w-5"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>
                                    )}

                                {userQuery.isLoading ||
                                    (userQuery.isFetching && (
                                        <div className="flex items-center justify-center">
                                            <Loading height={'8'} width={'8'} />
                                        </div>
                                    ))}
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Supplier
                                </dt>
                                <Listbox
                                    value={userCopy?.supplier_id}
                                    onChange={handleSupplierChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {getReadableSupplier(
                                                            userCopy?.supplier_id ||
                                                                0
                                                        )}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {Object.entries(
                                                            SUPPLIER
                                                        ).map(([id, name]) => (
                                                            <Listbox.Option
                                                                key={id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-indigo-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={id}
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate'
                                                                            )}
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                        </span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-indigo-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>{' '}
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Master
                                </dt>
                                <input
                                    onChange={handleMMerchantChange}
                                    type="text"
                                    name="mmerchant"
                                    id="mmerchant"
                                    value={userCopy?.mmerchant || ''}
                                    className={`${
                                        !validMMerchant && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>{' '}
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Business Contact Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        View and edit business contact information.
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2  gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Address
                                </dt>
                                <input
                                    onChange={handleAddressChange}
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={userCopy?.address || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    City
                                </dt>
                                <input
                                    onChange={handleCityChange}
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={userCopy?.city || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Postal Code
                                </dt>
                                <input
                                    onChange={handlePostalCodeChange}
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    value={userCopy?.postal_code || ''}
                                    className={`block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Province/State
                                </dt>
                                <Listbox
                                    value={userCopy?.provinces_id}
                                    onChange={handleProvinceChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {getReadableProvince(
                                                            userCopy?.provinces_id ||
                                                                0
                                                        )}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {Object.entries(
                                                            PROVINCES
                                                        ).map(([id, name]) => (
                                                            <Listbox.Option
                                                                key={id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-indigo-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={id}
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate'
                                                                            )}
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                        </span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-indigo-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Country
                                </dt>
                                <Listbox
                                    value={userCopy?.countries_id}
                                    onChange={handleCountryChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {getReadableCountry(
                                                            userCopy?.countries_id ||
                                                                0
                                                        )}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {Object.entries(
                                                            COUNTRIES
                                                        ).map(([id, name]) => (
                                                            <Listbox.Option
                                                                key={id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-indigo-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={id}
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate'
                                                                            )}
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                        </span>

                                                                        {selected && (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-indigo-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className={'border border-gray-100 rounded-md mt-4 p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Documents
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2  gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Business Registration
                                </dt>
                                <label
                                    htmlFor={`business-registration`}
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                    <div className={'flex space-x-2'}>
                                        <span>
                                            {extractFileName(
                                                userCopy?.business_registration_file_name ||
                                                    ''
                                            )}
                                        </span>
                                        <ArrowUpTrayIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        id={`business-registration`}
                                        name={`business-registration`}
                                        onChange={(e) =>
                                            handleUploadFile(
                                                e,
                                                userCopy?.id || 0,
                                                'businessregistration'
                                            )
                                        }
                                        type="file"
                                        className="sr-only"
                                        accept={'.pdf, .png, .jpg, .jpeg'}
                                    />
                                </label>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Proof of Address
                                </dt>
                                <label
                                    htmlFor={`proof-of-address`}
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                    <div className={'flex space-x-2'}>
                                        <span>
                                            {extractFileName(
                                                userCopy?.proof_of_address_file_name ||
                                                    ''
                                            )}
                                        </span>
                                        <ArrowUpTrayIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        id={`proof-of-address`}
                                        name={`proof-of-address`}
                                        onChange={(e) =>
                                            handleUploadFile(
                                                e,
                                                userCopy?.id || 0,
                                                'proofofaddress'
                                            )
                                        }
                                        type="file"
                                        className="sr-only"
                                        accept={'.pdf, .png, .jpg, .jpeg'}
                                    />
                                </label>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <DialogModal
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to cancel? Your changes will be lost."
                confirmAction={confirmCancel}
                cancelAction={cancelCancel}
            />
        </div>
    )
}
