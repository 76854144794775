import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DialogModal from '../../shared-components/DialogModal'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

interface Props {
    user: Paywell.API.V2.User | null
    userCopy: Paywell.API.V2.User | null
    updateUserInfo: (user: Paywell.API.V2.User) => void
    saveChanges: () => void
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}

export default function UserFeesEdit({
    user,
    userCopy,
    updateUserInfo,
    saveChanges,
}: Props) {
    const userIsAdminOrAgent =
        userCopy?.user_type_id === 2 || userCopy?.user_type_id === 3

    const navigate = useNavigate()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [validTx, setValidTx] = useState(true)
    const [validMdr, setValidMdr] = useState(true)
    const [validRequestMoneyMdr, setValidRequestMoneyMdr] = useState(true)
    const [validMin, setValidMin] = useState(true)
    const [validSettlement, setValidSettlement] = useState(true)
    const [validSettlementHoldFee, setValidSettlementHoldFee] = useState(true)
    const [validSplitBankingFee, setValidSplitBankingFee] = useState(true)
    const [validStatement, setValidStatement] = useState(true)
    const [validRental, setValidRental] = useState(true)
    const [validAgentCommission, setValidAgentCommission] = useState(true)
    const validForm =
        validTx &&
        validMdr &&
        validRequestMoneyMdr &&
        validMin &&
        validSettlement &&
        validSettlementHoldFee &&
        validSplitBankingFee &&
        validStatement &&
        validRental &&
        validAgentCommission

    useEffect(() => {
        validateForm()
    }, [userCopy])

    const validateForm = () => {
        if (!userCopy) return
        if (userCopy.mdr === '' || userCopy.mdr === null) {
            setValidMdr(false)
        } else {
            setValidMdr(true)
        }

        if (
            userCopy.request_money_mdr_fee === '' ||
            userCopy.request_money_mdr_fee === null
        ) {
            setValidRequestMoneyMdr(false)
        } else {
            setValidRequestMoneyMdr(true)
        }

        if (userCopy.min === '' || userCopy.min === null) {
            setValidMin(false)
        } else {
            setValidMin(true)
        }

        if (userCopy.settlement === '' || userCopy.settlement === null) {
            setValidSettlement(false)
        } else {
            setValidSettlement(true)
        }

        if (
            userCopy.settlement_hold_fee === '' ||
            userCopy.settlement_hold_fee === null
        ) {
            setValidSettlementHoldFee(false)
        } else {
            setValidSettlementHoldFee(true)
        }

        if (
            userCopy.split_banking_fee === '' ||
            userCopy.split_banking_fee === null
        ) {
            setValidSplitBankingFee(false)
        } else {
            setValidSplitBankingFee(true)
        }

        if (userCopy.statement === '' || userCopy.statement === null) {
            setValidStatement(false)
        } else {
            setValidStatement(true)
        }

        if (userCopy.rental === '' || userCopy.rental === null) {
            setValidRental(false)
        } else {
            setValidRental(true)
        }

        if (userIsAdminOrAgent) {
            if (
                userCopy.agent_commission === '' ||
                userCopy.agent_commission === null
            ) {
                setValidAgentCommission(false)
            } else {
                setValidAgentCommission(true)
            }
        }
    }

    const handleTxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        const isNumericOrEmpty = /^-?\d*\.?\d*$/.test(val)

        if (userCopy && val.length <= 8 && isNumericOrEmpty) {
            val = val === '' ? '0' : val

            // Convert the value to a number
            const numericValue = Number(val)

            updateUserInfo({
                ...userCopy,
                tx: numericValue,
            })
        }
    }

    const handleMDRChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 100)
            ) {
                updateUserInfo({
                    ...userCopy,
                    mdr: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    mdr: userCopy.mdr || '',
                })
            }
        }
    }

    const handleRequestMoneyTxChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        const isNumericOrEmpty = /^-?\d*\.?\d*$/.test(val)

        if (userCopy && val.length <= 8 && isNumericOrEmpty) {
            val = val === '' ? '0' : val

            // Convert the value to a number
            const numericValue = Number(val)

            updateUserInfo({
                ...userCopy,
                request_money_tx_fee: numericValue,
            })
        }
    }

    const handleRequestMoneyMdrChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    request_money_mdr_fee: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    request_money_mdr_fee: userCopy.request_money_mdr_fee || '',
                })
            }
        }
    }

    const handleMinMdrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    min: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    min: userCopy.min || '',
                })
            }
        }
    }

    const handleSettlementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    settlement: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    settlement: userCopy.settlement || '',
                })
            }
        }
    }

    const handleSettlementHoldFee = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    settlement_hold_fee: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    settlement_hold_fee: userCopy.settlement_hold_fee || '',
                })
            }
        }
    }

    const handleSplitBankingFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    split_banking_fee: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    split_banking_fee: userCopy.split_banking_fee || '',
                })
            }
        }
    }

    const handleMonthlyStatementChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    statement: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    statement: userCopy.statement || '',
                })
            }
        }
    }

    const handleRentalFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 9999)
            ) {
                updateUserInfo({
                    ...userCopy,
                    rental: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    rental: userCopy.rental || '',
                })
            }
        }
    }

    const handleTaxExemptChange = (value: boolean) => {
        if (userCopy) {
            updateUserInfo({
                ...userCopy,
                tax_exempt: value,
            })
        }
    }

    const handleAgentCommissionChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let val = e.target.value
        const isValidInput = /^-?\d*\.?\d{0,2}$/.test(val)

        if (userCopy && val.length <= 9 && isValidInput) {
            let numericValue = val === '' ? null : parseFloat(val)

            if (
                numericValue === null ||
                (numericValue >= 0 && numericValue <= 1)
            ) {
                updateUserInfo({
                    ...userCopy,
                    agent_commission: val === '' ? '' : val,
                })
            } else {
                updateUserInfo({
                    ...userCopy,
                    agent_commission: userCopy.agent_commission || '',
                })
            }
        }
    }
    const handleSaveChanges = () => {
        saveChanges()
    }

    const hasUnsavedChanges = () => {
        return JSON.stringify(user) !== JSON.stringify(userCopy)
    }

    const handleCancel = () => {
        if (!user) return
        if (hasUnsavedChanges()) {
            setIsDialogOpen(true) // Show the dialog if there are unsaved changes
        } else {
            navigate('/users/' + user.id + '/fees') // No unsaved changes, just navigate away
        }
    }

    const confirmCancel = () => {
        setIsDialogOpen(false) // Close the dialog
        if (user) updateUserInfo(user) // Reset the user to the original state (cancel changes
        navigate('/users/' + user?.id + '/fees') // Proceed with the cancel action
    }

    const cancelCancel = () => {
        setIsDialogOpen(false) // Close the dialog without canceling changes
    }

    const invalidClass =
        '!border-2 !border-rose-500 focus:ring-rose-500 focus:border-rose-500'

    return (
        <div className={`p-10`}>
            <div className="mb-2 flex justify-end space-x-2">
                <button
                    disabled={!validForm}
                    onClick={handleSaveChanges}
                    type="button"
                    className={`block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm disabled:bg-gray-400 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                    Save
                </button>
                <button
                    onClick={handleCancel}
                    type="button"
                    className="flex  rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Cancel
                </button>
            </div>
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Fees
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Charge per transaction
                                </dt>

                                <input
                                    type="text"
                                    onChange={handleTxChange}
                                    name="charge-per-transaction"
                                    id="charge-per-transaction"
                                    value={userCopy?.tx}
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    MDR rate
                                </dt>
                                <input
                                    onChange={handleMDRChange}
                                    type="text"
                                    name="user-mdr"
                                    id="user-mdr"
                                    value={userCopy?.mdr || ''}
                                    className={`${
                                        !validMdr && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-xs font-medium leading-6 text-gray-900">
                                    Request for eTransfer charge per transaction
                                </dt>
                                <input
                                    type="text"
                                    onChange={handleRequestMoneyTxChange}
                                    name="request-money-tx-fee"
                                    id="request-money-tx-fee"
                                    value={userCopy?.request_money_tx_fee}
                                    className={` block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Request for eTransfer MDR
                                </dt>
                                <input
                                    type="text"
                                    onChange={handleRequestMoneyMdrChange}
                                    name="request-money-mdr-fee"
                                    id="request-money-mdr-fee"
                                    value={userCopy?.request_money_mdr_fee}
                                    className={`${
                                        !validRequestMoneyMdr && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Minimum MDR
                                </dt>

                                <input
                                    type="text"
                                    onChange={handleMinMdrChange}
                                    name="min"
                                    id="min"
                                    value={userCopy?.min}
                                    className={`${
                                        !validMin && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Batch outs
                                </dt>
                                <input
                                    type="text"
                                    onChange={handleSettlementChange}
                                    name="settlement"
                                    id="settlement"
                                    value={userCopy?.settlement}
                                    className={`${
                                        !validSettlement && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Settlement hold fee
                                </dt>
                                <input
                                    type="text"
                                    onChange={handleSettlementHoldFee}
                                    name="settlement-hold-fee"
                                    id="settlement-hold-fee"
                                    value={userCopy?.settlement_hold_fee}
                                    className={`${
                                        !validSettlementHoldFee && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Split banking fee
                                </dt>

                                <input
                                    type="text"
                                    onChange={handleSplitBankingFee}
                                    name="split-banking-fee"
                                    id="split-banking-fee"
                                    value={userCopy?.split_banking_fee}
                                    className={`${
                                        !validSplitBankingFee && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Monthly Statement
                                </dt>
                                <input
                                    type="text"
                                    onChange={handleMonthlyStatementChange}
                                    name="monthly-statement"
                                    id="monthly-statement"
                                    value={userCopy?.statement}
                                    className={`${
                                        !validStatement && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Equipment Rental
                                </dt>

                                <input
                                    type="text"
                                    onChange={handleRentalFeeChange}
                                    name="rental-fee"
                                    id="rental-fee"
                                    value={userCopy?.rental}
                                    className={`${
                                        !validRental && invalidClass
                                    } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                />
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Tax Exempt
                                </dt>

                                <Listbox
                                    value={userCopy?.tax_exempt}
                                    onChange={handleTaxExemptChange}
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="relative mt-2">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {userCopy?.tax_exempt
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={true}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {'Yes'}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                        <Listbox.Option
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-indigo-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={false}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate'
                                                                        )}
                                                                    >
                                                                        {'No'}
                                                                    </span>

                                                                    {selected && (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                            {userIsAdminOrAgent && (
                                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Agent Commission
                                    </dt>

                                    <input
                                        type="text"
                                        onChange={handleAgentCommissionChange}
                                        name="agent-commission"
                                        id="agent-commission"
                                        value={userCopy?.agent_commission}
                                        className={`${
                                            !validAgentCommission &&
                                            invalidClass
                                        } block w-full md:w-fit rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6`}
                                    />
                                </div>
                            )}
                        </dl>
                    </div>
                </div>
            </div>
            <DialogModal
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
                title="Unsaved Changes"
                description="You have unsaved changes. Are you sure you want to cancel? Your changes will be lost."
                confirmAction={confirmCancel}
                cancelAction={cancelCancel}
            />
        </div>
    )
}
